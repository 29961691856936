import React, { useEffect, useState } from "react";
// import { TransactionBlock } from "@mysten/sui.js/transactions";
import { isValidSuiAddress } from "@mysten/sui.js/utils";
// import { ZkSignatureInputs, getZkSignature } from "@mysten/zklogin";
// import { Ed25519Keypair } from "@mysten/sui.js/keypairs/ed25519";
// import axios from "axios";
import CloseIcon from "../../../../../assets/img/close.svg";
import CheckIcon from "../../../../../assets/img/check-new.svg";
import CopyIcon from "../../../../../assets/img/copy.svg";
import {
  TransactionLoader,
  client,
  client_fullnode_url,
  decrypted_data,
  shorten,
  toastMsg,
} from "../../../../lib/helper";
import {
  Provider,
  Network,
  AptosAccount,
  AptosClient,
  CoinClient,
  FaucetClient,
} from "aptos";
const TxnSend = (props: any) => {
  const {
    identityKey,
    coinValue,
    coinList,
    setShowTxnSend,
    keyPair,
    setIsTxnSuccess,
  } = props;
  const isGLogin = localStorage.getItem("wallet:gin");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [txnDigest, setTxnDigest] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isPosTxn, setIsPosTxn] = useState(false);
  const [coinName, setCoinName] = useState("Sui");

  const onCopyText = () => {
    navigator.clipboard.writeText(txnDigest);
    toastMsg("Copied!");
  };

  function convertToOctas(inputAmount: string): number {
    const APT_TO_OCTAS = 100_000_000;

    if (!isNaN(Number(inputAmount))) {
      return Math.round(Number(inputAmount) * APT_TO_OCTAS);
    } else {
      // console.error("Invalid amount value:", inputAmount);
      return 0; // return 0 for invalid values
    }
  }

  const onPaymentSend = async () => {
    setShowLoading(true)
    try {
      const NODE_URL = "https://fullnode.testnet.aptoslabs.com";
      // const FAUCET_URL = "https://faucet.testnet.aptoslabs.com";

      // Create API and faucet clients.
      const client = new AptosClient(NODE_URL);
      // const faucetClient = new FaucetClient(NODE_URL, FAUCET_URL);

      // Create client for working with the coin module.
      const coinClient = new CoinClient(client);

      const octasValue = convertToOctas(amount);

      // if (octasValue !== null) {
      //   return  // Outputs: 10000000 for userInput = "0.1"
      // }


      // const provider = new Provider(Network.TESTNET);
      const tran = await coinClient.transfer(
        keyPair,
        // "0xef3a1a1ab3915aed6490a917d5b97ef32568f9c5772e35f44fc4de67a625f874",
        address,
        octasValue,
        {
          gasUnitPrice: BigInt(100),
        }
      );
      // console.log(tran);

      const hashh = await client.waitForTransaction(tran, {
        checkSuccess: true,
      });
      // console.log(tran);
      setTxnDigest(tran);
      setShowLoading(false);

    } catch (error) {
      console.error("Error sending payment:", error);
    }
  }


  // const resolveNameService = async (name: string) => {
  //   try {
  //     const address = await client.resolveNameServiceAddress({ name });
  //     return address;
  //   } catch (e) {
  //     return null;
  //   }
  // };


  // send part start
  // const txnWithZK = async (getAddress: any) => {
  //   const savedAddress = localStorage.getItem("wallet:address");
  //   const state = JSON.parse(localStorage.getItem("wallet:key") as any);
  //   const inputs: ZkSignatureInputs = JSON.parse(
  //     localStorage.getItem("wallet:inputs") as any
  //   );

  //   const tx = new TransactionBlock();
  //   const txnValue = Math.floor(+amount * 1_000_000_000);

  //   if (coinName.toLocaleLowerCase() == "sui") {
  //     const coins = tx.splitCoins(tx.gas, [tx.pure(txnValue)]);
  //     tx.transferObjects([coins], tx.pure(getAddress));
  //   } else {
  //     const getObjectkey = await getObjectId(txnValue);
  //     const [coin] = tx.splitCoins(tx.object(getObjectkey), [
  //       tx.pure(txnValue),
  //     ]);
  //     tx.transferObjects([coin], tx.pure(getAddress));
  //   }

  //   if (!state) {
  //     console.error("Required state not found in localStorage");
  //     return;
  //   }
  //   if (!inputs) {
  //     console.error("Required inputs not found in localStorage");
  //     return;
  //   }
  //   if (!savedAddress) {
  //     console.error("Required saved not found in localStorage");
  //     return;
  //   }
  //   tx.setSender(decrypted_data(savedAddress));

  //   const ephemeralKeyPair = Ed25519Keypair.fromSecretKey(
  //     Buffer.from(state.ephPrivate, "base64")
  //   );

  //   const preSign = await tx.sign({
  //     client: client,
  //     signer: ephemeralKeyPair,
  //   });

  //   const zkSignature = getZkSignature({
  //     inputs: inputs,
  //     maxEpoch: state.epoch,
  //     userSignature: preSign.signature,
  //   });

  //   const res = await client.executeTransactionBlock({
  //     transactionBlock: preSign.bytes,
  //     signature: zkSignature,
  //   });
  //   // Here you can emit the event or handle the response.
  //   if (res.digest) {
  //     setTxnDigest(res.digest);
  //     setShowLoading(false);
  //   }
  // };

  // const getObjectId = async (txnValue: any) => {
  //   const coin_type = coinList.find(
  //     (coin: any) => coinName == coin.metadata.name
  //   );

  //   let coinKey = "";
  //   if (coin_type) {
  //     coinKey = coin_type.getCoinKey;
  //   }
  //   const walletAddress = keyPair.getPublicKey().toSuiAddress();

  //   let res = await fetch(`${client_fullnode_url}`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       jsonrpc: "2.0",
  //       id: 1,
  //       method: "suix_getCoins",
  //       params: [walletAddress, coinKey],
  //     }),
  //   });
  //   let json = await res.json();
  //   if (json && json.result && json.result.data) {
  //     let isNotValid = 0;
  //     for (var i = 0; i < json.result.data.length; i++) {
  //       if (json.result.data[i].balance > txnValue) {
  //         return json.result.data[i].coinObjectId;
  //       } else {
  //         isNotValid += 1;
  //       }
  //     }
  //     if (isNotValid !== 0) {
  //       toastMsg("OOPS! You dont have sufficient balance");
  //       return 0;
  //     }
  //   }
  // };



  // const txnWithSui = async (getAddress: any) => {
  //   // const tx = new TransactionBlock();
  //   // const txnValue = Math.floor(+amount * 1_000_000_000);

  //   // if (coinName.toLocaleLowerCase() == "sui") {
  //   //   const coins = tx.splitCoins(tx.gas, [tx.pure(txnValue)]);
  //   //   tx.transferObjects([coins], tx.pure(getAddress));
  //   // } else {
  //   //   const getObjectkey = await getObjectId(txnValue);
  //   //   if (getObjectkey) {
  //   //     const [coin] = tx.splitCoins(tx.object(getObjectkey), [
  //   //       tx.pure(txnValue),
  //   //     ]);
  //   //     tx.transferObjects([coin], tx.pure(getAddress));
  //   //   }
  //   // }

  //   const NODE_URL = "https://fullnode.testnet.aptoslabs.com";
  //   // const FAUCET_URL = "https://faucet.testnet.aptoslabs.com";
  //   // Create API and faucet clients.
  //   const client = new AptosClient(NODE_URL);
  //   // const faucetClient = new FaucetClient(NODE_URL, FAUCET_URL);

  //   // Create client for working with the coin module.
  //   const coinClient = new CoinClient(client);

  //   // const provider = new Provider(Network.TESTNET);
  //   const tran = await coinClient.transfer(
  //     keyPair,
  //     "0xef3a1a1ab3915aed6490a917d5b97ef32568f9c5772e35f44fc4de67a625f874",
  //     1000,
  //     {
  //       gasUnitPrice: BigInt(100),
  //     }
  //   );
  //   console.log(tran);

  //   const hashh = await client.waitForTransaction(tran, {
  //     checkSuccess: true,
  //   });
  //   // }

  //   try {
  //     const result = client.signAndExecuteTransactionBlock({
  //       signer: keyPair,
  //       transactionBlock: tx,
  //       options: {
  //         showEffects: true,
  //       },
  //     });
  //     result.then((response: any) => {
  //       if (response && response.digest) {
  //         if (
  //           response &&
  //           response.effects &&
  //           response.effects.status &&
  //           response.effects.status.status === "success"
  //         ) {
  //           const txn_digest = response && response.digest;
  //           if (
  //             txn_digest !== "" &&
  //             txn_digest !== null &&
  //             txn_digest !== undefined
  //           ) {
  //             if (isPosTxn) {
  //               // txnWithSoundBox(amount);
  //               setTxnDigest(txn_digest);
  //               setShowLoading(false);
  //             } else {
  //               setTxnDigest(txn_digest);
  //               setShowLoading(false);
  //             }
  //           }
  //         } else if (
  //           response &&
  //           response.effects &&
  //           response.effects.status &&
  //           response.effects.status.status === "failure"
  //         ) {
  //           setShowLoading(false);
  //           toastMsg(`Transaction Failed: ${response.effects.status.error}`);
  //         }
  //       } else {
  //         setShowLoading(false);
  //         toastMsg("Transaction Failed");
  //       }
  //     });
  //   } catch (error) { }
  //   // };

  //   // const onPaymentSend = async () => {
  //   //   setShowLoading(true);
  //   //   if (address == "" && amount == "") {
  //   //     toastMsg("something went wrong");
  //   //     setShowLoading(false);
  //   //     return;
  //   //   }

  //   //   if (amount == "") {
  //   //     toastMsg("amount must needed");
  //   //     setShowLoading(false);
  //   //     return;
  //   //   }

  //   //   // if (address.includes(".sui")) {
  //   //   //   let getAddress = await resolveNameService(address);
  //   //   //   if (!getAddress) {
  //   //   //     toastMsg("Is not a valid name");
  //   //   //     setShowLoading(false);
  //   //   //     return;
  //   //   //   } else {
  //   //   //     if (isGLogin == "yes") {
  //   //   //       txnWithZK(getAddress);
  //   //   //     } else {
  //   //   //       txnWithSui(getAddress);
  //   //   //     }
  //   //   //     return;
  //   //   //   }
  //   //   // } else {
  //   //   //   const isValidAddress = isValidSuiAddress(address);
  //   //   //   if (!isValidAddress) {
  //   //   //     toastMsg("Is not a valid address");
  //   //   //     setShowLoading(false);
  //   //   //     return;
  //   //   //   }
  //   // }

  //   // var regex = /^[0-9]+$/;
  //   // if (!amount.match(regex)) {
  //   //   toastMsg("must have input number");
  //   //   setShowLoading(false);
  //   //   return;
  //   // }

  //   if (!(coinValue > amount)) {
  //     toastMsg("you dont have enough balance");
  //     setShowLoading(false);
  //     return;
  //   }

  //   // if (isGLogin == "yes") {
  //   //   // txnWithZK(address);
  //   // } else {
  //   txnWithSui(address);
  //   // }
  // };

  useEffect(() => {
    if (identityKey !== false) {
      const isSuiAddress = isValidSuiAddress(identityKey);
      if (isSuiAddress) {
        setAddress(identityKey);
      } else {
        const parsedData = JSON.parse(identityKey);
        if (parsedData.merchant_address) {
          // setIsPosTxn(true);
          setAddress(parsedData.merchant_address);
        }
      }
    }
  }, []);

  return (
    <>
      {showLoading && <div className="black-bg"></div>}
      <div className="txn-send-wrapper">
        <div className="txn-heading-content inner_header">
          {txnDigest != "" ? (
            <>
              <img
                src={CloseIcon}
                alt="Back"
                onClick={() => {
                  setIsTxnSuccess(true), setShowTxnSend(false);
                }}
              />
              <h5>BlockBolt</h5>
            </>
          ) : (
            <>
              <img
                src={CloseIcon}
                alt="close"
                onClick={() => setShowTxnSend(false)}
              />
              <h5>Send Coin</h5>
            </>
          )}
        </div>
        <div>
          {txnDigest !== "" ? (
            <div className="txn-send-success">
              <img src={CheckIcon} alt="BlockBolt" className="check_icon" />
              <h4>Transaction completed successfully</h4>
              <div className="send_success_details">
                <p>
                  Amount{" "}
                  <span className="send_success_details_inner">
                    {amount} APT
                  </span>
                </p>
                <p>
                  To{" "}
                  <span className="send_success_details_inner">
                    {shorten(address)}
                  </span>
                </p>
                <p className="transaction_ID">
                  TXID{" "}
                  <span className="send_success_details_inner">
                    {shorten(txnDigest)}
                    <img
                      src={CopyIcon}
                      onClick={() => onCopyText()}
                      className="copy_icon"
                    />
                  </span>
                </p>
              </div>
              <div className="explorer wallet-common-button">
                <button
                  className="wallet-common-button"
                  onClick={() => {
                    setIsTxnSuccess(true), setShowTxnSend(false);
                  }}
                >
                  Back to main
                </button>
              </div>
            </div>
          ) : (
            <div className="txn-send-form">
              <p>Amount</p>
              <input
                type="number"
                className="form-control"
                placeholder="0.01"
                onChange={(e) => setAmount(e.target.value)}
              />
              <p>Address</p>
              {identityKey == false ? (
                <input
                  type="text"
                  className="form-control"
                  placeholder="0x3121399d6ca64e08428d7e0010ac23c269ec379ac6f7fa411cb1c994c11b6f63"
                  onChange={(e) => setAddress(e.target.value)}
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  disabled
                  value={address}
                />
              )}
              {/* <div className="selectCoin"> */}
              {/* <p>Select Coin</p> */}
              {/* <div className="select-field-wrapper">
                  <select
                    className="input selectCoinInner"
                    id="coin"
                    name="coin"
                    value={coinName}
                    onChange={(e) => setCoinName(e.target.value)}
                    defaultValue="SUI"
                  >
                    {coinList.map((coin: any) => {
                      return (
                        <option
                          value={coin.metadata.name}
                          key={coin.metadata.name}
                        >{`${coin.metadata.name}`}</option>
                      );
                    })}
                  </select>
                </div> */}
              {/* </div> */}
              <div className="gas_fees">
                <p>Estimated Gas Fees</p>
                <p>--</p>
              </div>

              <button
                className="wallet-common-button"
                onClick={() => onPaymentSend()}
              >
                Send
              </button>
            </div>
          )}
        </div>
        {showLoading && <TransactionLoader />}
      </div>
    </>
  );
};

export default TxnSend;
